html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.582),
      rgba(12, 8, 24, 0.904));

  --image-gradient: linear-gradient(to bottom left,
      rgba(17, 16, 16, 0.678),
      rgba(12, 10, 22, 0.863));

  --imp-text-color: #7b70f0;

  --main-color: #2a6ef8;
  --clear-blue: #71a0ff;
  --color-btn-contact: #2a6ef8;
  --color-btn-contact-opacity: #2a6ff8c9;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(126, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: var(--main-color) !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  width: 6em;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: var(--main-color);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: 0;
  /* Assurez-vous que la section a un z-index supérieur à -1 */
  background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  height: 100vh;
}

/* Ajoutez une classe au composant Tilt pour ajuster son z-index */
.tilt-container {
  position: relative;
  z-index: 1;
  /* Assurez-vous que le composant Tilt a un z-index supérieur à 0 */
}


.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: var(--main-color);
}

@media screen and (max-width: 767px) {

  .home-section {
    width: 100%;
    background-image: none;
    background-position: none;
    background-repeat: none;
    padding: 0 !important;
    height: 50vh;
  }

  

  .heading {
    font-size: 1.5em !important;
  }

  .heading-name {
    font-size: 2em !important;
  }

  .home-cards {
    width: 100% !important;
    margin-right: 0 auto;
  }

  .col {
    padding: 0 !important;
  }

}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: var(--clear-blue) !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: var(--main-color) !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding: 90px !important;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .home-about-section {
    flex-direction: column !important;
    padding: 50px !important;
  }

  .home-about-section p {
    text-align: justify;
  }

  .home-about-body {
    font-size: 1em !important;
  }

  .tilt-container {
    display: none !important;
  }

  .home-about-cards .col {
   display: flex;
    justify-content: center;
  }

  .col a {
    display: block !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    transform: translateX(-100px);
    width: 220px !important;
  }

  .card-right {
    margin-left: 100px;
  }

  .card-right,
  .card-right {
    margin-top: 20px;
  }
}

.home-about-left-side {
  width: 50%;
}

.home-about-right-side {
  width: 50%;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
  width: 100%;
}

/* .home-about-cards {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  justify-content: center !important;
} */


.home-about-cards {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
  gap: 10px !important;
  width: 80% !important;
  height: 500px !important;
}

@media (max-width: 767px) {
  .home-about-cards {
    grid-template-columns: 1fr;
    width: 100% !important;
    margin-bottom: 400px !important;
  }

  .home-cards {
    width: 90% !important;
    margin-bottom: 20px !important;
  }
}

.home-about-cards a {
  text-decoration: none !important;
  color: black;
}

.home-about-cards a:hover {
  text-decoration: none !important;
  color: black;
  cursor: default;
}

.home-cards {
  padding: 5px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease 0s !important;
  width: 230px;
  height: 230px;
  background-color: var(--clear-blue) !important;
  cursor: pointer;
  text-align: left;
  margin: 0 auto;
}

.home-cards:hover {
  /* transform: translateY(-10px) !important; */
  box-shadow: 0 0 5px var(--main-color) !important;
  text-shadow: 0 0 2px var(--main-color) !important;
  transition: all 0.3s ease 0s !important;
}

.home-cards:hover .icon-card-move {
  transition: all 0.3s ease 0s !important;
  display: block !important;
}

.icon-card-move {
  position: absolute;
  bottom: 20px;
  right: 25px;
  display: none;
}

.card-left {
  margin-left: 100px;
}

.card-bottom {
  margin-top: 20px;
}


.home-about-body {
  padding-top: 50px;
  font-size: 1.2em;
  text-align: justify;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--main-color);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--main-color);
}

.home-social-icons:hover {
  color: var(--main-color);
  box-shadow: 0 0 5px var(--main-color);
  text-shadow: 0 0 2px var(--main-color);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: var(--main-color) !important;
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer {
    margin-top: 150px;
  }

  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  /* background-image: var(--section-background-color) !important; */
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  /* background-image: var(--section-background-color) !important; */
  color: white !important;
}

.tech-icon-wrapper {
  display: flex !important;
  justify-content: center;
  padding-bottom: 20px;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid white !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  background-color: white !important;
  text-decoration: none !important;
  width: 300px !important;
}


.tech-icons img {
  width: 60% !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid var(--main-color) !important;
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: var(--main-color) !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-color: var(--color-btn-contact) !important;

  -webkit-transition: all 0.8s ease;
  /* Safari et Chrome */
  -moz-transition: all 0.8s ease;
  /* Firefox */
  -ms-transition: all 0.8s ease;
  /* Internet Explorer 9 */
  -o-transition: all 0.8s ease;
  /* Opera */
  transition: all 0.8s ease;
}

.fork-btn-inner:hover {
  background-color: var(--main-color) !important;
  border-color: var(--color-btn-contact) !important;
  color: black !important;

  -webkit-transition: all 0.8s ease;
  /* Safari et Chrome */
  -moz-transition: all 0.8s ease;
  /* Firefox */
  -ms-transition: all 0.8s ease;
  /* Internet Explorer 9 */
  -o-transition: all 0.8s ease;
  /* Opera */
  transition: all 0.8s ease;
}

.fork-btn-inner::after {
  display: none !important;
}






/************************************************************* Projets ********************************************************************/

#projects {
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin: 0%;
  padding: 0%;
}

#projects p {
  color: #fff;
}

.download_btn {
  padding: 15px 20px;
  text-decoration: none;
  justify-content: center;
  border-radius: 0.5em;
  border-width: 0px;
  background-color: var(--main-color);
  font-size: 16px;
  color: #FFFFFF;
  line-height: 16px;

  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  margin-top: 70px;
  margin-bottom: 150px;

  -webkit-transition: all 0.8s ease;
  /* Safari et Chrome */
  -moz-transition: all 0.8s ease;
  /* Firefox */
  -ms-transition: all 0.8s ease;
  /* Internet Explorer 9 */
  -o-transition: all 0.8s ease;
  /* Opera */
  transition: all 0.8s ease;
}

.download_btn:hover {
  background-color: rgb(68, 109, 255);
  color: black;
}

.div_download_btn {
  display: flex;
  justify-content: center;
}

.project_text {
  position: relative;
  width: 400px;
  height: 400px;
  background: linear-gradient(to bottom, rgb(68, 109, 255) 50%, rgb(203, 47, 246) 100%);
  border-radius: 0.7em;
  color: white;
  box-shadow: 20px 20px rgba(0, 0, 0, .15);
  transition: all .4s ease;
  padding-inline: 20px;
}



.project_text::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(8px);
  z-index: -1;
}

.project_text>* {
  position: relative;
  z-index: 1;
}


.project_text2 {
  position: relative;
  width: 350px;
  height: 400px;
  background: linear-gradient(to bottom, rgb(68, 109, 255) 50%, rgb(203, 47, 246) 100%);
  border-radius: 0.7em;
  color: white;
  box-shadow: -20px 20px rgba(0, 0, 0, .15);
  transition: all .4s ease;
}

.project_text2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(8px);
  z-index: -1;
}

.project_text2>* {
  position: relative;
  z-index: 1;
}


.project_text:hover {
  /* border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ; */
  box-shadow: 10px 10px rgba(0, 0, 0, .15);
}

.project_text2:hover {
  /* border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ; */
  box-shadow: -10px 10px rgba(0, 0, 0, .15);
}

.project_text p,
.project_text2 p {
  color: white;
  text-align: justify;
}

.project_text p b,
.project_text2 p b {

  border-bottom: 1px solid #f1f1f1;
}

.project_text h2,
.project_text2 h2 {
  color: white;
  padding: 20px;
  padding-bottom: 2px;
  text-align: center;
}

.project_text span,
.project_text2 span {
  color: 000;
  padding: 0px;
  margin: 0px;
  text-align: center;

}

.text_show {
  display: flex;
  justify-content: space-around;
}

#img_p1 {
  width: 30%;
  border-radius: 20px;
  margin: 0px 200px;
  padding: 0;

  width: 400px;
  height: 400px;
  max-width: 500px;
  max-height: 500px;
}



.show_project {
  display: flex;
  justify-content: space-between;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.first-image {
  margin: 0;
  padding: 0;
  height: 400px;
  border: 2px solid transparent;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

@media screen and (max-width: 767px) {
  .image-container {
    width: 500px;
  }
}

.half-image {
  margin: 0;
  padding: 0;
  height: 200px;
  /* La moitié de la hauteur de la première image */
  align-self: flex-end;
  /* Les demi-images sont alignées à la fin du conteneur parent */
  border: 2px solid transparent;
}

.half-top {
  border-top-right-radius: 15px;
}

.half-bottom {
  border-bottom-right-radius: 15px;
}

@media (max-width: 768px) {
  .show_project {
    flex-direction: column;
    /* Les éléments passent en colonne sur les petits écrans */
  }

  .image-container {
    width: 90%;
    /* Chaque conteneur prend 100% de la largeur sur les petits écrans */
    margin: 0 auto;
    /* Les conteneurs sont centrés sur les petits écrans */
  }

  .first-image,
  .half-image {
    max-width: 100%;
    /* Les images s'ajustent à la largeur de leur conteneur parent */
    height: auto;
    /* Les images s'ajustent à la hauteur de leur conteneur parent */
    width: 400px;
    border-radius: 15px;
  }

  .half-image {
    margin-top: 10px;
    /* Ajoute un espace entre les demi-images */
  }
}




video {
  object-fit: contain;
  width: 700px;
  height: 400px;
  border-radius: 1em;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.4));
}

#projects p a {
  color: #fff;
  text-decoration: none;

  /*** Button ***/
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 5px auto;

}

@media screen and (max-width:999px) {
  .text_show {
    display: block;
    justify-content: center;
  }

  .project_text2,
  .project_text {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;

    font-size: 16px;
    padding-bottom: 10px;
  }

  .show_project {
    margin-bottom: 20px;
  }

  .show_project video {
    width: 100%;
    height: 100%;
  }
}

#sortButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

#sortButtons h2 {
  margin-right: 20px;
  margin-top: 10px;
  font-size: 20px;


}

.sort-btn {
  margin-inline: 7px;
  transition: all 0.3s ease 0s;
  border: 0px solid transparent;
  /* Définissez une bordure transparente */
}

.sort-btn.checked {
  background-color: #fff;
  /* Couleur de fond lorsque le bouton est "checked" */
  border-color: #007bff;
  /* Couleur de la bordure lorsque le bouton est "checked" */
  color: black;
  /* Couleur du texte lorsque le bouton est "checked" */
  transition: all 0.3s ease 0s;
  border: 4px solid #007bff;
  /* Définissez une bordure transparente */
}












/* ############################################### COMPETENCES / SKILLS ######################################################## */

#skill {
  transition: background-color 4s ease;
  background: linear-gradient(70deg, #3f69ff, #8426f6, #7490f5, #42cbed, #9a5af5);
  background-size: auto;
  background-size: 500% 250%;
  animation: degradeFond 30s ease infinite;
}

@keyframes degradeFond {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.inline-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#skill h1 {
  color: white;
}


.skill_box {
  background-color: white;
  min-height: 0px;
  width: calc(100% - 100px);
  padding: 50px 50px 50px 50px;
  min-width: 230px;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.4));
}

.skill_box ul {
  display: flex;
  justify-content: space-between;
}

.skill_title {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding: 10px;
  color: white;
}

.skill_box {
  display: flex;
  justify-content: center;
}

.skill_box,
.skill_title {
  margin-left: 50px;
  margin-right: 50px;
}

.div_box {
  /*display: flex;
    justify-content: center;*/
  /* Pour changer le style de positionnement des box */

  margin-bottom: 50px;
}

.description_box {
  width: 400px;
  max-width: 400px;
}

.skill_box li img {
  -webkit-transition: all 0.8s ease;
  /* Safari et Chrome */
  -moz-transition: all 0.8s ease;
  /* Firefox */
  -ms-transition: all 0.8s ease;
  /* Internet Explorer 9 */
  -o-transition: all 0.8s ease;
  /* Opera */
  transition: all 0.8s ease;
}

.skill_box li img:hover {
  -webkit-transform: scale(1.25);
  /* Safari et Chrome */
  -moz-transform: scale(1.25);
  /* Firefox */
  -ms-transform: scale(1.25);
  /* Internet Explorer 9 */
  -o-transform: scale(1.25);
  /* Opera */
  transform: scale(1.25);
}

.icone_text {
  text-align: center;
}

.icone_text p {
  color: black;
  margin-top: 10px;
}

@media screen and (max-width:999px) {
  .div_box {
    width: 100%;
    margin: auto auto;
  }
}

/**************************************************************************************************************************************/



#skills_section {
  transition: background-color 4s ease;
  background: linear-gradient(70deg, #3f69ff, #8426f6, #7490f5, #42cbed, #9a5af5);
  background-size: auto;
  background-size: 500% 250%;
  animation: degradeFond 30s ease infinite;
  height: 800px;
  padding-top: 0px;
}

.skills_end {
  transition: background-color 4s ease;
  background: linear-gradient(70deg, #3f69ff, #8426f6, #7490f5, #42cbed, #9a5af5);
  background-size: auto;
  background-size: 500% 250%;
  animation: degradeFond 30s ease infinite;
}

#skills_section .skills_content {
  margin: auto;
  width: 80%;
}

#skills_section .box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(28, 28, 51, 0.15));
  filter: drop-shadow(0px 0px 10px rgba(28, 28, 51, 0.15));
}

#skills_section .box .menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#skills_section .box .menu .category {
  width: 280px;
  border-radius: 20px;
  background-color: white;
  white-space: nowrap;
  padding: 30px 40px 30px 0px;
  margin-right: -40px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

#skills_section .box .box_content {
  width: 100%;
  min-height: 475px;
  border-radius: 20px;
  background-color: white;
  padding: 50px 60px 50px 60px;
}

@keyframes enter_anim {
  from {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes enter_anim {
  from {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

#skills_section .box .box_content .skills_list {
  width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 50px;
  opacity: 0;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  -webkit-animation: enter_anim 0.5s ease 1 forwards;
  animation: enter_anim 0.5s ease 1 forwards;
}

#skills_section .box .box_content .skills_list .skill {
  height: 125px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100px;
  text-decoration: none;
}

#skills_section .box .box_content .skills_list .skill img {
  width: 95%;
  -webkit-transform: scale(0.88);
  -ms-transform: scale(0.88);
  transform: scale(0.88);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

#skills_section .box .box_content .skills_list .skill:hover img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

#skills_section .box .box_content .skills_list .skill span {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
  color: var(--black_text);
  text-align: center;
}

#skills_section .box .category_title {
  white-space: nowrap;
  margin: 0px 0px -50px 0px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 22px;
  margin-bottom: -40px;
}

#skills_section h1 {
  color: white;
  padding-top: 50px;
}

#skills_section a {
  color: black;
}


@media screen and (max-width: 1000px) {

  #skills_section {
    height: 100%;
    padding-bottom: 100px;
  }

  #skills_section .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 70px;
    min-height: 0px;
  }

  #skills_section .box .box_content {
    min-height: 0px;
    width: calc(100% - 100px);
    padding: 50px 50px 50px 50px;
    min-width: 230px;
  }

  #skills_section .box .box_content .skills_list {
    grid-gap: 25px;
  }

  @media screen and (max-width: 565px) {
    #skills_section .box .box_content .skills_list {
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 500px) {
    #skills_section .box .box_content .skills_list {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    #skills_section .box .box_content {
      width: calc(100% - 50px);
      padding: 40px 25px 40px 25px;
    }
  }

  @media screen and (max-width: 465px) {
    #skills_section .box .box_content .skills_list {
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }
  }
}


























/* ############################################### CONTACT ######################################################## */

#contact_section {
  height: 91.5vh;
  background-color: transparent;
}

.content_leaning {
  margin-top: 100px;
}

#contact_section .content.leaning {
  margin-top: calc(calc(clamp(110px, 7vw, 130px) + 3.3vw) * 1.65);
  margin-bottom: calc(clamp(100px, 7vw, 130px) * 2);
}

#contact_section .section_title {
  color: var(--black_title);
  margin-bottom: 70px;
}

#contact_section .contact_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 780px) {
  #contact_section .contact_content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .social_line {
    margin-bottom: 0px !important;
  }
}

.social_line {
  margin-bottom: 200px;

}

.social_text a {
  text-decoration: none;
}

#contact_section .social_line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}

#contact_section .social_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 25px 10px 25px 25px;
  border-radius: 20px;
  background-color: white;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(28, 28, 51, 0.2));
  filter: drop-shadow(0px 0px 10px rgba(28, 28, 51, 0.2));
  gap: 15px;
  text-decoration: none;
  -webkit-transition: padding 0.5s ease-in-out;
  -o-transition: padding 0.5s ease-in-out;
  transition: padding 0.5s ease-in-out;

  height: 100px;
}

#contact_section .social_link:hover {
  padding-right: 25px;
}

#contact_section .social_link img {
  width: 50px;
}

#contact_section .social_link .social_text {
  overflow: hidden;
  width: 0px;
  -webkit-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

#contact_section .social_link:hover .social_text {
  width: var(--size);
}

#contact_section .social_link .social_text span {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 16px;
  color: black;
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  #contact_section .social_link:hover {
    padding-right: 10px;
  }

  #contact_section .social_link:hover .social_text {
    width: 0px;
  }
}

#contact_section h1 {
  padding-top: 100px;
}

a.dl-cv {
  text-align: center;
  transform: translateY(120px);
  color: black;
}

.separator {
  width: 500px;
  height: 1px;
  background-color: #000;
}







/************************************************************* EXPERIENCES **************************************************************/

#experience {
  background-color: #fff;
  padding-top: 50px;
}

.marge-exp {
  margin-inline: 200px;
}

.exp-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .card-exp {
    width: 250px !important;
  }
}

.card-exp {
  margin: 1rem;
  width: 500px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #f4f6f8;
  border-radius: 10px;
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  transition: all .3s ease;
}

.card-exp:hover {
  box-shadow: 0 0 11px #007bff6a;
  transition: all .3s ease;
}

.card-header:first-child {
  border-radius: 8px 8px 0 0;
}

.card-header h3 {
  color: black;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-3 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.card-header {
  padding: 1rem 2rem;
  margin-bottom: 0;
  background-color: #f4f6f8;
  border-bottom: 2px solid #f4f6f8;
  text-align: start;
  font-family: 'Montserrat', sans-serif;
}

.card-body {
  flex: 1 1 auto;
  padding: 2rem 2rem;
}

.card-body p {
  margin-bottom: 0;
  color: black;
  text-align: start;
  text-align: justify;

}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.text-small {
  font-size: .875rem;
}

.text-muted {
  color: #6c757d !important;
  margin-top: 10px;
}




.experience-divider {
  background-color: #fff;
  width: 5px;
  height: 200px;
  margin: 0;
}

.experience-divider-bullet {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}




.container-project-details {
  width: 600px;
  color: #fff;
  margin: 0 auto;
  text-align: justify;
  line-height: 2;
  word-spacing: 2px;
}

.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out
}

@keyframes octocat-wave {

  0%,
  100% {
    transform: rotate(0)
  }

  20%,
  60% {
    transform: rotate(-25deg)
  }

  40%,
  80% {
    transform: rotate(10deg)
  }
}

@media (max-width:500px) {
  .github-corner:hover .octo-arm {
    animation: none
  }

  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out
  }
}



.github-icon-btn-card {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  transition: all 0.3s ease;
}

.github-icon-btn-card:hover {
  color: #b7c1fa;
  transition: all 0.3s ease;
}



/* Particle.css */
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place l'élément en arrière-plan */
}


.sort-btns-container {
  background-color: black;
  padding: 10px;
  width: 240px;
  margin : 0 auto;
  border-radius: 1.5em;
  box-shadow: -0.5rem 0.5rem 1rem rgba(53, 40, 82, 0.693), inset -0.25rem 0.25rem 0.5rem rgb(72, 54, 112);
}

.sort-btn {
  background-color: transparent;
  color: white;
}

.active-btn {
  background-color: var(--clear-blue);
  border: none;
  border-radius: 1.5em;
  cursor: pointer;
  transition: all 0.3s ease;
  color: black;
  padding: 5px;
  padding-inline: 20px;
}


.video-project-details {
  width: 100%;
  margin-bottom: 50px;
  border-radius: .5em;
}


details {
  margin: 0 auto;
  width: 600px;
  color: #000;
  text-align: justify;
  line-height: 2;
  word-spacing: 2px;
  background-color: #ffffffa2;
  border-radius:.9em;
  border: 2px solid #fff;
  padding: 5px 20px;
  transition: all 0.3s ease;
}

details:hover {
  background-color: #ffffffc8;
  transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  details {
    width: 100%;
  }
}